import { gql, useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useCallback, useMemo, Fragment } from 'react';
import LoadingPage from '../Components/LoadingPage';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

export default function MyMeeting() {
  const [myTiming, setMyTiming] = useState([]);
  const { data: userData, loading, error } = useQuery(
    gql`
      query {
        userId
        role
        eventId
      }
    `,
    { onError: (e) => console.log(JSON.stringify(e, null, 2)) }
  );

  // const visitorId = userData?.userId;

  // const { data: myAvailability, loading: myAvailabilityLoading, error: myAvailabilityError } = useQuery(GET_MY_AVAILABILITY_DETAIL, {
  //   variables: { visitorId },
  //   onError: (e) => console.log(JSON.stringify(e, null, 2))
  // });

  // useEffect(() => {
  //   if (myAvailability && myAvailability?.myAvailability) {
  //     const parsedData = JSON.parse(myAvailability.myAvailability);
  //     setMyTiming(parsedData);
  //   }
  // }, [myAvailability]);

  if (loading) return <LoadingPage />;
  if (error) return (
    <Box sx={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h6" component="b" color="red">
        Something went wrong...
      </Typography>
    </Box>
  )
  if (userData?.role !== 'visitor') {
    return (
      <Box sx={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" component="b" color="red">
          Sorry! You are not authorized...
        </Typography>
      </Box>
    );
  }

  return <ViewMeetingCalendar visitorId={userData?.userId}  />;
}

function ViewMeetingCalendar({ visitorId }) {
  const [meetings, setMeetings] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_ALL_MY_VISITOR_SCHEDULED_MEETINGS, {
    variables: { visitorId },
    onError: (e) => console.log(JSON.stringify(e, null, 2))
  }
  );

  const [updateMeetingStatus] = useMutation(UPDATE_MEETING_STATUS);

  useEffect(() => {
    if (data && data.myVisitorMeetings) {
      const parsedData = JSON.parse(data.myVisitorMeetings);
      setMeetings(parsedData);
    }
  }, [data]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    console.log(event.status);
  };

  const handleCloseDialog = () => {
    setSelectedEvent(null);
    refetch()
  };

  const handleUpdateStatus = (status) => {
    updateMeetingStatus({ variables: { id: selectedEvent.id, status } }).then(() => {
      refetch();
      handleCloseDialog();
    });
  };

  // const { defaultDate, minTime, maxTime } = useMemo(() => {
  //   if (myTiming.length > 0) {
  //     const startDate = new Date(myTiming[0].startDate);
  //     const endDate = new Date(myTiming[0].endDate);
  //     const meetingStartTime = myTiming[0].meetingStartTime.split(':');
  //     const meetingEndTime = myTiming[0].meetingEndTime.split(':');

  //     return {
  //       defaultDate: startDate,
  //       minTime: new Date(1970, 1, 1, meetingStartTime[0], meetingStartTime[1]),
  //       maxTime: new Date(1970, 1, 1, meetingEndTime[0], meetingEndTime[1])
  //     };
  //   }
  //   return {
  //     defaultDate: new Date(),
  //     minTime: new Date(1970, 1, 1, 0, 0),
  //     maxTime: new Date(1970, 1, 1, 23, 59)
  //   };
  // }, [myTiming]);

  return (
    <Fragment>
      <div className="height600">
        <Calendar
          // defaultDate={defaultDate}
          defaultView={Views.WEEK}
          events={meetings.map((meeting) => ({
            ...meeting,
            start: new Date(JSON.parse(meeting.requested_slot).startTime),
            end: new Date(JSON.parse(meeting.requested_slot).endTime),
            title: meeting.title,
            bgColor: meeting.status === 'pending' ? 'yellow' : meeting.status === 'accept' ? 'green' : 'red',
            textColor: 'black'
          }))}
          localizer={localizer}
          onSelectEvent={handleSelectEvent}
          // min={minTime}
          // max={maxTime}
          style={{ height: '80vh' }}
          eventPropGetter={(event) => ({
            style: { backgroundColor: event.bgColor, color: event.textColor }
          })}
        />
      </div>
      <Dialog open={!!selectedEvent} onClose={handleCloseDialog}>
        <DialogTitle>{selectedEvent?.title}</DialogTitle>
        <DialogContent>
          <Stack direction ="column" spacing={2}>
          <Typography>Name: {selectedEvent?.exhibitor_name}</Typography>
          <Typography>Company: {selectedEvent?.exhibitor_company}</Typography>
          <Typography>Email: {selectedEvent?.exhibitor_email}</Typography>
          <Typography>Booth: {selectedEvent?.booth}</Typography>
          <Typography>Status: {selectedEvent?.status}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => handleUpdateStatus('accept')} color="primary">
            Accept
          </Button>
          <Button onClick={() => handleUpdateStatus('reject')} color="secondary">
            Reject
          </Button> */}
          <Button onClick={handleCloseDialog} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const GET_ALL_MY_VISITOR_SCHEDULED_MEETINGS = gql`
  query($visitorId: String!) {
    myVisitorMeetings(visitorId: $visitorId)
  }
`;

const GET_MY_AVAILABILITY_DETAIL = gql`
  query($visitorId: String!) {
    myAvailability(visitorId: $visitorId)
  }
`;

const UPDATE_MEETING_STATUS = gql`
  mutation($id: ID!, $status: String!) {
    updateMeetingStatus(id: $id, status: $status) 
  }
`;
