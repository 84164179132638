import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../Assets/eventegration.png";
import bgImg from "../Assets/login_bg.jpeg";
import "./style.css";
import LoadingPage from "../Components/LoadingPage";

export default function LoginPage() {
  // const [isValidEmail, setIsValidEmail]= React.useState(false)
  const [login, { loading, error }] = useMutation(gql`
    mutation ($eventId: String!,$role: String! $email: String!, $password: String!) {
      token: login(eventId: $eventId, role: $role, email: $email, password: $password)
    }
  `);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const eventIdParam = searchParams.get("eventId");
  const emailParam = searchParams.get("email");
  const roleParam = searchParams.get("role");

  // const {name, loading:nameLoading, error:nameError}= useQuery(gql`
  //   query($email:String!,$role)
  //   `)
  const onLogin = async (variables) => {
    return login({ variables }).then((resp) => {
      localStorage.setItem("auth.account.eventegration.com", resp.data.token);
      localStorage.setItem("eventId", variables.eventId);
      localStorage.setItem("role", variables.role);
      navigate("/dashboard");
    });
  };

  return (
    <Grid
      container
      sx={{
        minWidth: "100%",
        height: "100vh",
        // backgroundColor: "#121212",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Grid item md={6} sx={{ display: { xs: "none", md: "flex" } }}>
        <img src={bgImg} alt="Bg" style={{ width: "100%", height: "100%" }} />
      </Grid>
      <Grid item md={6}>

        <Typography
          align="center"
          variant="h3"
          gutterBottom
          sx={{
            zIndex: 2,
            py:2,
            color: "black",
            // textShadow: "0px 2px 4px rgba(255, 255, 255, 0.2)",
            // borderRadius: 8,
            // backgroundColor: "rgba(0, 0, 0, 0.6)",
          }}
        >
          ExpoConnect System
        </Typography>
        <Container maxWidth="sm">
          <Paper
            sx={{
              p: 3,
              // mt: 4,
              borderRadius: 7,
              backgroundColor: "rgba(244, 244,244, 0.5)",
              boxShadow: "0px 2px 4px rgba(255, 255, 255, 0.1)",
            }}
            variant="outlined"
          >
            <Typography
              align="center"
              variant="h5"
              sx={{ color: "black", my: 2, fontWeight: 500 }}
              gutterBottom
            >
              Login Panel
            </Typography>
            {emailParam && <Typography
              align="left"
              variant="body1"
              sx={{ color: "black", my: 2 }}
              gutterBottom
            >
              <b>Hello,</b> {emailParam}<br />
              Please enter password to access your account
            </Typography>}
            <LoginForm
              onSubmit={onLogin}
              eventIdParam={eventIdParam}
              emailParam={emailParam}
              roleParam={roleParam}
            />

            {loading && <LoadingPage />}

            {!!error && (
              <motion.div
                animate={{
                  x: [30, -30, 15, -15, 8, 0],
                }}
                transition={{ duration: 0.4 }}
              >
                <Typography color="error" align="center" sx={{ mt: 2 }}>
                  {error.message}
                </Typography>
              </motion.div>
            )}
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
}

function LoginForm({ onSubmit, eventIdParam, emailParam, roleParam }) {
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      eventId: eventIdParam || "",
      role: roleParam || "visitor",
      email: emailParam || "",
      password: "",
    },
  });

  const { data, loading, error } = useQuery(gql`
    query {
      eventList: getEventList {
        id
        name
      }
    }
  `);

  if (loading) return <LinearProgress />;
  if (error) return <Typography>{error.message}</Typography>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {!roleParam && (
          <Controller
            control={control}
            name="role"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Select
                {...field}
                inputRef={ref}
                fullWidth
                error={!!error}
                // color="success"
                label="Role"
              >
                <MenuItem value="visitor">Visitor</MenuItem>
                <MenuItem value="exhibitor">Exhibitor</MenuItem>
              </Select>
            )}
          />
        )}
        {!eventIdParam && (
          <Controller
            control={control}
            name="eventId"
            rules={{ maxLength: 20, required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                helperText={error?.message}
                error={!!error}
                color="success"
                label="Event Id"
              />
            )}
          />
        )}

        {!emailParam && (
          <Controller
            control={control}
            name="email"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                helperText={error?.message}
                error={!!error}
                label="Email/UserId"
                required
                fullWidth
                autoComplete="new-password"
                color="success"
              />
            )}
          />
        )}

        <Controller
          control={control}
          name="password"
          rules={{ required: "Required" }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              inputRef={ref}
              helperText={error?.message}
              error={!!error}
              label="Password"
              required
              fullWidth
              type="password"
              color="success"
            />
          )}
        />

        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={formState.isSubmitting}
          sx={{
            background: "rgba(252, 196, 1, 0.8)",
            "&:hover": {
              background: "rgba(252, 196, 1, 1)",
            },
          }}
        >
          Enter
        </LoadingButton>
      </Stack>
      <Stack px={4} mt={6} direction={"column"} justifyContent={"end"}>
        <Typography align="center" color={"black"} fontSize={10} gutterBottom>
          Powered by...
        </Typography>
        <Stack direction={"row"} justifyContent={"center"}>
          <Box
            width="30%"
            sx={{ backgroundColor: "transparent", borderRadius: 1, cursor: "pointer" }}
            onClick={() => {
              window.location.href = "https://eventegration.com";
            }}
          >
            <img src={logo} width="95%" alt="Logo" />
          </Box>
        </Stack>
      </Stack>
    </form>
  );
}
