import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Typography, Button, LinearProgress, Stack, IconButton } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { IMAGES_API_URL } from '../../constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MAX_FILE_SIZE = 1000 * 1024; // 500KB

export default function ProfilePicWithUpdateButton({ eventId, userId, role }) {
  const [logoExists, setLogoExists] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');

  const UPLOAD_LOGO_MUTATION = gql`
    mutation ($file: Upload!, $eventId: String!, $userId: String!) {
      uploadEventLogo(eventId: $eventId, userId: $userId, file: $file)
    }
  `;

  const [uploadLogo, { loading, error }] = useMutation(UPLOAD_LOGO_MUTATION);
  const [deleteLogo] = useMutation(gql`
    mutation ($eventId: String!, $userId: String!) {
      deleteEventLogo(eventId: $eventId, userId: $userId)
    }
  `);

  const checkLogoExists = () => {
    const url = IMAGES_API_URL + `expomeet/${eventId}/${userId}.png`;
    const img = new Image();
    img.onload = () => {
      setLogoExists(true);
      setLogoUrl(url);
    };
    img.onerror = () => {
      setLogoExists(false);
    };
    img.src = url;
  };

  useEffect(() => {
    checkLogoExists();
  }, [eventId, userId]);

  const handleUpload = async (event) => {
    const file = event?.target?.files[0];
    if (!file) return;
    
    if (file.size > MAX_FILE_SIZE) {
      toast.error('File size exceeds 1MB. Please upload a smaller file.');
      return;
    }

    try {
      const response = await uploadLogo({
        variables: {
          eventId: eventId,
          userId: userId,
          file: file,
        },
      });
      if (response.data) {
        const url = `${IMAGES_API_URL}expomeet/${eventId}/${userId}.png?timestamp=${new Date().getTime()}`;
        setLogoUrl(url);
        setLogoExists(true);
      }
    } catch (error) {
      console.error('Error uploading logo:', error);
      toast.error('Error uploading logo. Please try again.');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteLogo({
        variables: {
          eventId: eventId,
          userId: userId,
        },
      });
      setLogoExists(false);
      setLogoUrl('');
    } catch (error) {
      console.error('Error deleting logo:', error);
      toast.error('Error deleting logo. Please try again.');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="2px dashed grey"
      borderRadius="8px"
      width="200px"
      height="200px"
      position="relative"
      bgcolor={logoExists ? 'transparent' : 'grey.200'}
    >
      {logoExists ? (
        <>
          <img src={logoUrl} alt="Profile Logo" style={{ width: '100%', height: '100%', objectFit:"contain" }} />
        </>
      ) : (
        <Typography variant="h6" align="center">
          Upload Logo
        </Typography>
      )}
      <input
        type="file"
        accept="image/png"
        style={{
          opacity: 0,
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          cursor: 'pointer',
        }}
        onChange={(e) => handleUpload(e)}
      />
      {logoExists && (
        <Box>
          <IconButton onClick={() => handleDelete()} color="error" variant="outlined">
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      {!logoExists && (
        <Typography variant="body2" align="center">
          PNG type only. Max size 1MB.
        </Typography>
      )}
      {loading && <LinearProgress sx={{ width: '100%', mt: 2 }} />}
      {error && (
        <Typography color="error" align="center" sx={{ mt: 2 }}>
          {error.message}
        </Typography>
      )}
      <ToastContainer />
    </Box>
  );
}

