import React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const ApiTestPage = () => {
    const [formData, setFormData] = React.useState({
        eventId: "1638075500",
        role: "visitor",
        "Full Name": "Yahiya",
        Designation: "Dev",
        Company: "Dev",
        Email: "test@example.com",
        Country: "OM",
        Mobile: "6123456",
        calling_code: "+968",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async () => {
        const jsonData = JSON.stringify(formData);

        try {
            //   const response = await axios.post("http://localhost:4005/webhook/register", { jsonData });
            // const response = await axios.post("http://localhost:4013/webhook/register", { jsonData }, { headers: { 'Content-Type': 'application/json' } });
            const response = await axios.post("https://webhook.eventegration.com/webhook/register", { jsonData }, { headers: { 'Content-Type': 'application/json' } });
            // const response = await axios.post(
            //     "https://api-reg.eventegration.com/webhook/register",

            //     { jsonData },
            //     { headers: { 'Content-Type': 'application/json' } }
            // );
            console.log(response.data);
        } catch (error) {
            console.error(error.response ? error.response.data : error.message);
        }
    };


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
            }}
        >
            <Typography variant="h6" gutterBottom>
                Registration Form
            </Typography>
            <TextField
                label="Event ID"
                name="eventId"
                value={formData.eventId}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="First Name"
                name="First Name"
                value={formData["Full Name"]}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            {/* <TextField
                label="Last Name"
                name="Last Name"
                value={formData["Last Name"]}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            /> */}
            <TextField
                label="Designation"
                name="Designation"
                value={formData.Designation}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Company"
                name="Company"
                value={formData.Company}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Email"
                name="Email"
                value={formData.Email}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Country"
                name="Country"
                value={formData.Country}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Mobile"
                name="Mobile"
                value={formData.Mobile}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Calling Code"
                name="calling_code"
                value={formData.calling_code}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
            />
            {/* Add more fields as necessary */}
            <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </Box>
    );
};

export default ApiTestPage;
