import { gql, useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import LoadingPage from '../Components/LoadingPage';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Stack } from '@mui/material';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

export default function ManageMeeting() {
  const [myTiming, setMyTiming] = useState([]);
  const { data: userData, loading, error } = useQuery(
    gql`
      query {
        userId
        role
        eventId
      }
    `,
    { onError: (e) => console.log(JSON.stringify(e, null, 2)) }
  );

  const exhibitorId = userData?.userId;

  const { data: myAvailability, loading: myAvailabilityLoading, error: myAvailabilityError } = useQuery(GET_MY_AVAILABILITY_DETAIL, {
    variables: { exhibitorId },
    onError: (e) => console.log(JSON.stringify(e, null, 2))
  });

  useEffect(() => {
    if (myAvailability && myAvailability?.myAvailability) {
      const parsedData = JSON.parse(myAvailability.myAvailability);
      setMyTiming(parsedData);
    }
  }, [myAvailability]);

  if (loading || myAvailabilityLoading) return <LoadingPage />;
  if (error || myAvailabilityError) return <p>Something went wrong...</p>;
  if (userData?.role !== 'exhibitor') {
    return (
      <Box sx={{ minHeight: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" component="b" color="red">
          Sorry! You are not authorized...
        </Typography>
      </Box>
    );
  }

  return <ViewMeetingCalendar exhibitorId={userData?.userId} myTiming={myTiming} />;
}

function ViewMeetingCalendar({ exhibitorId, myTiming }) {
  const [meetings, setMeetings] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { data, loading, error, refetch } = useQuery(GET_ALL_MY_SCHEDULED_MEETINGS, {
    variables: { exhibitorId },
    onError: (e) => console.log(JSON.stringify(e, null, 2))
  });

  const [updateMeetingStatus] = useMutation(UPDATE_MEETING_STATUS);

  useEffect(() => {
    if (data && data.myMeetings) {
      const parsedData = JSON.parse(data.myMeetings);
      setMeetings(parsedData);
    }
  }, [data]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    console.log(event);
  };

  const handleCloseDialog = () => {
    setSelectedEvent(null);
  };

  const handleUpdateStatus = (status) => {
    updateMeetingStatus({ variables: { id: selectedEvent.id, status } }).then(() => {
      refetch();
      handleCloseDialog();
    });
  };

  const { defaultDate, minTime, maxTime } = useMemo(() => {
    if (myTiming.length > 0) {
      const startDate = new Date(myTiming[0].startDate);
      const endDate = new Date(myTiming[0].endDate);
      const meetingStartTime = myTiming[0].meetingStartTime.split(':');
      const meetingEndTime = myTiming[0].meetingEndTime.split(':');

      return {
        defaultDate: moment(startDate).startOf('week').toDate(),
        minTime: new Date(1970, 1, 1, meetingStartTime[0], meetingStartTime[1]),
        maxTime: new Date(1970, 1, 1, meetingEndTime[0], meetingEndTime[1])
      };
    }
    return {
      defaultDate: moment().startOf('week').toDate(),
      minTime: new Date(1970, 1, 1, 0, 0),
      maxTime: new Date(1970, 1, 1, 23, 59)
    };
  }, [myTiming]);
  
  console.log("defaultDate", defaultDate);

  return (
    <Fragment>
      <div className="height600">
        <Calendar
          defaultDate={defaultDate}
          defaultView={Views.WEEK}
          events={meetings.map((meeting) => ({
            ...meeting,
            start: new Date(JSON.parse(meeting.requested_slot).startTime),
            end: new Date(JSON.parse(meeting.requested_slot).endTime),
            title: meeting.title,
            bgColor: meeting.status === 'pending' ? 'yellow' : meeting.status === 'accept' ? 'green' : 'red',
            textColor: 'black'
          }))}
          localizer={localizer}
          onSelectEvent={handleSelectEvent}
          min={minTime}
          max={maxTime}
          style={{ height: '80vh' }}
          eventPropGetter={(event) => ({
            style: { backgroundColor: event.bgColor, color: event.textColor }
          })}
        />
      </div>
      <Dialog open={!!selectedEvent} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant='h6' color={"black"} fontWeight={"bolder"} textTransform={"capitalize"}>
            {selectedEvent?.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack direction={"column"} spacing={2}>
            <Typography variant='body1' color={"black"} fontWeight={"bold"}>
              Name: <Typography variant="body1" color={"GrayText"} component="span">{selectedEvent?.visitor_name}</Typography>
            </Typography>
            <Typography variant='body1' color={"black"} fontWeight={"bold"}>
              Email: <Typography variant="body1" color={"GrayText"} component="span">{selectedEvent?.visitor_email}</Typography>
            </Typography>
            <Typography variant='body1' color={"black"} fontWeight={"bold"}>
              Status: <Typography variant="body1" component="span" backgroundColor={getStatusColor(selectedEvent?.status).bgColor} color={getStatusColor(selectedEvent?.status).textColor} paddingX={2} paddingY={1} borderRadius={10}> {getStatusText(selectedEvent?.status)}</Typography>
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          {selectedEvent?.status === "cancelled" ? "" : selectedEvent?.status === "accept" ?
            (<>
              <Button onClick={() => handleUpdateStatus('reject')} color="error" variant='outlined'>Reject</Button>
              <Button onClick={() => handleUpdateStatus('cancelled')} color="inherit" variant='outlined'>Cancel Meeting</Button>
            </>) : selectedEvent?.status === "reject" ? (<>
              <Button onClick={() => handleUpdateStatus('accept')} variant='outlined' color="success">Accept</Button>
              <Button onClick={() => handleUpdateStatus('cancelled')} color="inherit" variant='outlined'>Cancel Meeting</Button>
            </>) : (<>
              <Button onClick={() => handleUpdateStatus('accept')} variant='outlined' color="success">Accept</Button>
              <Button onClick={() => handleUpdateStatus('reject')} variant='outlined' color="warning">Reject</Button>
            </>)}
          <Button onClick={handleCloseDialog} color="error" variant='contained'>Close</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function getStatusText(status) {
  switch (status) {
      case 'pending':
          return 'Pending';
      case 'reject':
          return 'Rejected';
      case 'accept':
          return 'Accepted';
      case 'cancelled':
          return 'Cancelled';
      default:
          return 'Unknown';
  }
}

function getStatusColor(status) {
  switch (status) {
      case 'pending':
          return { bgColor: 'yellow', textColor: 'black' };
      case 'reject':
          return { bgColor: 'red', textColor: 'white' };
      case 'accept':
          return { bgColor: 'green', textColor: 'white' };
      case 'cancelled':
          return { bgColor: 'gray', textColor: 'white' };
      default:
          return { bgColor: 'inherit', textColor: 'inherit' };
  }
}

const GET_ALL_MY_SCHEDULED_MEETINGS = gql`
  query($exhibitorId: String!) {
    myMeetings(exhibitorId: $exhibitorId)
  }
`;

const GET_MY_AVAILABILITY_DETAIL = gql`
  query($exhibitorId: String!) {
    myAvailability(exhibitorId: $exhibitorId)
  }
`;

const UPDATE_MEETING_STATUS = gql`
  mutation($id: ID!, $status: String!) {
    updateMeetingStatus(id: $id, status: $status) 
  }
`;
