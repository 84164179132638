import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, Paper, Stack, Typography, TextField, MenuItem, Select, InputLabel, FormControl, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LoadingPage from '../Components/LoadingPage';
import { IMAGES_API_URL } from '../constants';
import BookMeeting from './BookMeeting';
import { toast, ToastContainer } from 'react-toastify';
import dummyurl from "../Assets/logoAvtar.png";

export default function ExhibitorsList() {
    const { data: userData, loading, error } = useQuery(
        gql`
          query {
            userId,
            role,
            eventId
          }
        `,
        { onError: (e) => console.log(JSON.stringify(e, null, 2)) }
    );

    if (loading) return <LoadingPage />;
    if (error) return <p>Something went wrong...</p>;
    if (userData?.role !== "visitor") {
        return (
            <Box sx={{ minHeight: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='h6' component={"b"} color="red">Sorry! You are not authorized...</Typography>
            </Box>);
    }

    return (
        <ExhibitorsListItem visitorId={userData?.userId} eventId={userData?.eventId} />
    );
}

function ExhibitorsListItem({ visitorId, eventId }) {
    const [exhibitors, setExhibitors] = useState([]);
    const [filteredExhibitors, setFilteredExhibitors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('all');
    const [showBookingUI, setShowBookingUI] = useState(false);
    const [selectedExhibitor, setSelectedExhibitor] = useState(null);

    const { data, loading, error, refetch } = useQuery(GET_EXHIBITORS_LIST, {
        variables: { visitorId },
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    console.log("Received data from backend:", data?.getExhibitorsList);

    const [cancelMeeting] = useMutation(CANCEL_MEETING);

    useEffect(() => {
        if (data && data.getExhibitorsList) {
            const parsedData = JSON.parse(data.getExhibitorsList);
            console.log("Parsed data:", parsedData);
            setExhibitors(parsedData);
            setFilteredExhibitors(parsedData);
        }
    }, [data]);

    useEffect(() => {
        filterExhibitors();
    }, [searchTerm, filter]);

    const filterExhibitors = () => {
        let filtered = exhibitors;

        if (searchTerm) {
            filtered = filtered.filter(exhibitor =>
                exhibitor.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                exhibitor.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
                exhibitor.booth.toLowerCase().includes(searchTerm.toLowerCase()) ||
                exhibitor.title?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (filter === 'booked') {
            filtered = filtered.filter(exhibitor => exhibitor.alreadyBooked);
        } else if (filter === 'notBooked') {
            filtered = filtered.filter(exhibitor => !exhibitor.alreadyBooked);
        }

        setFilteredExhibitors(filtered);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleBookingClick = (exhibitor) => {
        console.log(exhibitor);
        setSelectedExhibitor(exhibitor);
        setShowBookingUI(true);
    };

    const handleModifyClick = (exhibitor) => {
        setSelectedExhibitor(exhibitor);
        setShowBookingUI(true);
    };

    const handleCloseBookingUI = () => {
        setShowBookingUI(false);
        setSelectedExhibitor(null);
        refetch();
    };
// console.log(selectedExhibitor);
    const handleCancelMeeting = (id) => {
        if (window.confirm("Are you sure?")) {
            cancelMeeting({ variables: { id } })
                .then(() => {
                    toast.success("Meeting cancelled successfully!");
                    refetch();
                })
                .catch((error) => {
                    toast.error(`Error: ${error.message}`);
                });
        }
    };

    const getLogoUrl = async (userId) => {
        const url = `${IMAGES_API_URL}expomeet/${eventId}/${userId}.png`;
        const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
            img.onload = () => resolve(url);
            img.onerror = () => resolve(dummyurl);
        });
    };

    // console.log("filteredExhibitors", filteredExhibitors);
    // console.log("exhibitors", exhibitors);

    if (loading) return <LoadingPage />;
    if (error) return <p>Something went wrong...</p>;

    return (
        <Box>
            <ToastContainer />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    sx={{ mr: 2 }}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel>Filter</InputLabel>
                    <Select
                        value={filter}
                        onChange={handleFilterChange}
                        label="Filter"
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="booked">Booked</MenuItem>
                        <MenuItem value="notBooked">Not Booked</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {filteredExhibitors.length > 0 ? (
                <Grid container spacing={2}>
                    {filteredExhibitors.map((exhibitor) => (
                        <Grid item xs={12} sm={12} md={12} key={exhibitor.id}>
                            <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <ExhibitorLogo userId={exhibitor.id} eventId={eventId} />
                                    </Grid>
                                    <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                                        <Typography variant="h6">Name: {exhibitor.name}</Typography>
                                        <Typography variant="body1">Company: {exhibitor.company}</Typography>
                                        <Typography variant="h5">{`Booth: ${exhibitor.booth}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        {exhibitor.alreadyBooked ? (
                                            <Stack direction={'column'} spacing={2}>
                                                <Typography variant="h6" fontSize={18} textTransform={"capitalize"}>
                                                    Agenda: {exhibitor.title}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Meeting Date: {formatDate(JSON.parse(exhibitor.requested_slot).startTime)}
                                                </Typography>
                                                <Typography variant="body2">
                                                    Meeting Time: {formatTime(JSON.parse(exhibitor.requested_slot).startTime)} - {formatTime(JSON.parse(exhibitor.requested_slot).endTime)}
                                                </Typography>
                                                <Stack direction={'row'} spacing={2}>
                                                    <Typography variant="body2" component="span" >
                                                        Request Status: <Typography variant="body2" component="span" backgroundColor={getStatusColor(exhibitor.status).bgColor} color={getStatusColor(exhibitor.status).textColor} paddingX={2} paddingY={1} borderRadius={10}> {getStatusText(exhibitor.status)}</Typography>
                                                    </Typography>
                                                </Stack>
                                                <Stack direction="row" justifyContent="center" spacing={2}>
                                                    <Button variant="contained" color="secondary" disabled={exhibitor.status === "accept" || exhibitor.status === "reject"} onClick={() => handleModifyClick(exhibitor.id)}>Modify</Button>
                                                    <Button variant="outlined" color="error" onClick={() => handleCancelMeeting(exhibitor.id)}>Cancel Meeting</Button>
                                                </Stack>
                                            </Stack>
                                        ) : (
                                            <Button variant="contained" color="primary" onClick={() => handleBookingClick(exhibitor.id)}>Book Meeting</Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="body1">No exhibitors found.</Typography>
            )}

            <Dialog open={showBookingUI} onClose={handleCloseBookingUI} maxWidth="md" fullWidth>
                <DialogContent>
                    <BookMeeting exhibitorId={selectedExhibitor} visitorId={visitorId} eventId={eventId} handleCloseBookingUI={handleCloseBookingUI} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseBookingUI} variant='outlined' color="error">Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function ExhibitorLogo({ userId, eventId }) {
    const [logoUrl, setLogoUrl] = useState(dummyurl);

    const getLogoUrl = async (userId) => {
        // console.log(">>>>>>>>>>>",userId);
        const url = `${IMAGES_API_URL}expomeet/${eventId}/${userId}.png`;
        const img = new Image();
        img.src = url;

        return new Promise((resolve) => {
            img.onload = () => resolve(url);
            img.onerror = () => resolve(dummyurl);
        });
    };

    useEffect(() => {
        async function fetchLogo() {
            const url = await getLogoUrl(userId, eventId);
            setLogoUrl(url);
        }
        fetchLogo();
    }, [userId, eventId]);

    return (
        <img src={logoUrl} alt="Exhibitor Logo" style={{ height: "200px", width: "200px", objectFit:"contain" }} />
    );
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}

function getStatusColor(status) {
    switch (status) {
        case 'pending':
            return { bgColor: 'yellow', textColor: 'black' };
        case 'reject':
            return { bgColor: 'red', textColor: 'white' };
        case 'accept':
            return { bgColor: 'green', textColor: 'white' };
        case 'cancelled':
            return { bgColor: 'gray', textColor: 'white' };
        default:
            return { bgColor: 'inherit', textColor: 'inherit' };
    }
}

function getStatusText(status) {
    switch (status) {
        case 'pending':
            return 'Pending';
        case 'reject':
            return 'Rejected';
        case 'accept':
            return 'Accepted';
        case 'cancelled':
            return 'Cancelled';
        default:
            return 'Unknown';
    }
}

const GET_EXHIBITORS_LIST = gql`
  query($visitorId: String!) {
    getExhibitorsList(visitorId: $visitorId)
  }
`;

const CANCEL_MEETING = gql`
  mutation ($id: String!) {
    cancelMeeting(id: $id)
  }
`;
