import { gql, useMutation } from "@apollo/client";
import { Icon } from "@iconify/react";
import { Box, Button, CircularProgress, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

export function PasswordUpdate({ close, user }) {
    // const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    // console.log(user);
    const Id = user;
    const [mutate, { error, loading }] = useMutation(CHANGE_PASSWORD, {
        onCompleted: (data) => {
            reset();
            setMessage(data?.changePassword?.message);
            
            setTimeout(() => {
                close();
            }, 3000); 
        },
        onError: (error) => {
            console.error('Error updating password: ', error);
        }
    });
    

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setError,
        clearErrors,
        reset
    } = useForm();
    // console.log(user.id, Id);
    // console.log(user);
    const onSubmit = (data) => {
        if (data.newPassword !== data.confirmNewPassword) {
            setError('confirmNewPassword', {
                type: 'manual',
                message: 'Passwords do not match!'
            });
        }

        mutate({ variables: { oldPassword: data.oldPassword, newPassword: data.newPassword, Id } });
    };

    const handleClose = () => close();
    const newPassword = watch('newPassword');
    const confirmNewPassword = watch('confirmNewPassword');

    React.useEffect(() => {
        if(newPassword){
            if (newPassword.length < 8) { 
                setError('newPassword', { // changed from 'confirmPassword' to 'newPassword'
                    type: 'manual',
                    message: 'Minimum password length is 8!'
                });
            } else if (!/^[a-zA-Z0-9]+$/.test(newPassword)) { // Check if password is alphanumeric
                setError('newPassword', {
                    type: 'manual',
                    message: 'Password should be alphanumeric!'
                });
            } 
            else {
                clearErrors('confirmNewPassword');
                clearErrors('newPassword');  // Clear errors for 'newPassword' as well
            }
        }
        if (newPassword && confirmNewPassword) {
            if (newPassword !== confirmNewPassword) {
                setError('confirmNewPassword', {
                    type: 'manual',
                    message: 'Passwords do not match!'
                });
            } else {
                clearErrors('confirmNewPassword');
                clearErrors('newPassword');  // Clear errors for 'newPassword' as well
            }
        }
    }, [newPassword, confirmNewPassword, setError, clearErrors]);
    
    return (
        <div>
            <Modal open={true} onClose={() => close()} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(7px)',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px'
                    }}
                >
                    <IconButton style={{ position: 'absolute', right: '0', top: '0' }} onClick={handleClose}>
                    <Icon icon="zondicons:close" />
                    </IconButton>
                    <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        sx={{ color: 'black', textAlign: 'center', marginBottom: 3 }}
                    >
                        Change Password
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            <Controller
                                name="oldPassword"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="password"
                                        label="Old Password"
                                        color="secondary"
                                        error={!!errors.oldPassword}
                                        helperText={errors.oldPassword?.message}
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: '8px',
                                            '& .MuiInputLabel-root': { color: 'black' },
                                            '& .MuiInputBase-input': { color: 'black' }
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="newPassword"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="password"
                                        label="New Password"
                                        color="secondary"
                                        error={!!errors.newPassword}
                                        helperText={errors.newPassword?.message}
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: '8px',
                                            '& .MuiInputLabel-root': { color: 'black' },
                                            '& .MuiInputBase-input': { color: 'black' }
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="confirmNewPassword"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        type="password"
                                        label="Confirm New Password"
                                        error={!!errors.confirmNewPassword}
                                        helperText={errors.confirmNewPassword?.message}
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            borderRadius: '8px',
                                            '& .MuiInputLabel-root': { color: 'black' },
                                            '& .MuiInputBase-input': { color: 'black' }
                                        }}
                                    />
                                )}
                            />
                            {error && (
                                <Typography variant="body1" color={'red'} textAlign={'center'}>
                                    {error.message}
                                </Typography>
                            )}
                            {message && (
                                <Typography variant="body1" color={'green'} textAlign={'center'}>
                                    {message}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={loading}
                                sx={{
                                    marginTop: '16px',
                                    width: '100%',
                                    backgroundColor: 'rgba(204, 119, 34, 0.5)',
                                    color: 'white',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(24, 119, 242, 0.5)',
                                        boxShadow: 'none'
                                    }
                                }}
                            >
                                Submit {loading && <CircularProgress size={20} color="secondary" sx={{ marginLeft: 2 }} />}
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
const CHANGE_PASSWORD = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!, $Id: ID!) {
        changePassword(oldPassword: $oldPassword, newPassword: $newPassword, Id: $Id) {
            message
        }
    }
`;