import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import ProfilePicWithUpdateButton from '../Components/DashboardComponent/ProfilePicWithUpdateButton'
import { gql, useQuery } from '@apollo/client';
import ProfileForm from '../Components/DashboardComponent/ProfileForm';
import BookMeeting from './BookMeeting';
import MeetingSetup from './MeetingSetup';

export default function Profile() {

  const { data: userData } = useQuery(
    gql`
          query {
            userId,
            role,
            name,
            company
          }
        `,
    { onError: (e) => console.log(JSON.stringify(e, null, 2)) }
  );
  const eventId = localStorage.getItem("eventId")
  // console.log(eventId, userData?.userId);
  return (
    <Box >
      {
        !userData && (
          <>
            <Typography variant='body1' color={"red"}>Sorry!, Something Went Wrong</Typography>
          </>
        )
      }
      {
        (userData?.role !== "visitor" && userData?.role !== "exhibitor") && (
          <>
            <Typography variant='body1' color={"red"}>Sorry!, you are not allowed</Typography>
          </>
        )
      }
      {
        userData?.role === "exhibitor" && (
          <>
            <Grid container spacing={1} >
              <Grid item md={12}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ textTransform: "capitalize" }}
                >
                  {`${userData?.company}'s Profile`}
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ProfilePicWithUpdateButton userId={userData?.userId} role={userData?.role} eventId={eventId} />
              </Grid>
              <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ProfileForm userId={userData?.userId} eventId={eventId} role={userData?.role} />
              </Grid>
            </Grid>
            {/* <BookMeeting exhibitorId={userData?.userId} /> */}
          </>
        )
      }
      {
        userData?.role === "visitor" && (
          <>
            <Grid container spacing={1} >
              <Grid item md={12}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ textTransform: "capitalize" }}
                >
                  {`${userData?.name}'s Profile`}
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ProfilePicWithUpdateButton userId={userData?.userId} role={userData?.role} eventId={eventId} />
              </Grid>
              <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ProfileForm userId={userData?.userId} eventId={eventId} role={userData?.role} />
              </Grid>
            </Grid>
            {/* <BookMeeting exhibitorId={userData?.userId} /> */}
          </>
        )
      }


    </Box>
  )
}
