import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import AppLayout from "./Layouts/AppLayout";
import DashboardLayout from "./Layouts/DashboardLayout";
import LoginPage from "./Pages/LoginPage";
import LogoutPage from "./Pages/LogoutPage";
import MyVisitorsPage from "./Pages/MyVisitorsPage";
import ScanningVisitorsPage from "./Pages/ScanningVisitorsPage";
import TestPage from "./Pages/TestPage";
import AddStaffPage from "./Pages/AddStaffPage";
import InsightPage from "./Pages/InsightPage";
import LoadingPage from "./Components/LoadingPage";
import Dashboard from "./Pages/Dashboard";
import MeetingSetup from "./Pages/MeetingSetup";
import ManageMeeting from "./Pages/ManageMeeting";
import ExhibitorsList from "./Pages/ExhibitorsList";
import MyMeetings from "./Pages/MyMeetings";
import Profile from "./Pages/Profile";
import ApiTestPage from "./Pages/APITestPage";

export default function IndexRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/:eventId/scanner" element={<ScanningVisitorsPage />} /> */}

        <Route path="/" element={<AppLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogoutPage />} />
          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route index element={<Dashboard />} />
            {/* exhibitor route  */}
            <Route path="setup" element={<MeetingSetup />} />
            <Route path="manage" element={<ManageMeeting />} />
            {/* <Route path="insight" element={<InsightPage />} /> */}
            {/* visitor route  */}
            <Route path="exhibitors" element={<ExhibitorsList />} />
            <Route path="meetings" element={<MyMeetings />} />
            {/* <Route path="records" element={<StoragePage />} /> */}
            <Route path="profile" element={<Profile />} />
            <Route path="apitest" element={<ApiTestPage />} />
            <Route path="test" element={<TestPage />} />
          </Route>
          <Route index element={<Navigate to="dashboard" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function RequireAuth({ children }) {
  const [auth, setAuth] = React.useState(null);

  React.useEffect(() => {
    setAuth(!!localStorage.getItem("auth.account.eventegration.com"));
  }, []);

  if (auth === null) {
    return (
      <LoadingPage />
    );
  } else if (auth === false) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
}
