import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import LoadingPage from '../LoadingPage';
import { Box, Paper, Typography, Grid, Stack, Divider } from '@mui/material';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell
} from 'recharts';

const GET_MEETING_INSIGHT = gql`
  query ($eventId: String!, $exhibitorId: String!) {
    result: getAppointmentInsights(eventId: $eventId, exhibitorId: $exhibitorId)
  }
`;

const COLORS = ['#4CAF50', '#F44336', '#FFC107', '#9E9E9E'];

export default function InsightsComponent({ eventId, exhibitorId }) {
    const [insightsData, setInsightsData] = useState([]);
    const { data: getInsights, loading: insightsLoading, error: insightsError } = useQuery(GET_MEETING_INSIGHT, {
        variables: {
            eventId,
            exhibitorId
        }
    });

    useEffect(() => {
        if (getInsights && typeof getInsights?.result === "string") {
            try {
                const parsedData = JSON.parse(getInsights.result);
                setInsightsData(parsedData);
            } catch (error) {
                console.error("Error parsing insights data:", error);
            }
        }
    }, [getInsights]);

    const totals = insightsData.reduce((acc, data) => {
        acc.total += data.total || 0;
        acc.accept += data.accept || 0;
        acc.reject += data.reject || 0;
        acc.pending += data.pending || 0;
        acc.cancelled += data.cancelled || 0;
        return acc;
    }, { total: 0, accept: 0, reject: 0, pending: 0, cancelled: 0 });

    const pieData = [
        { name: 'Accepted', value: totals.accept },
        { name: 'Rejected', value: totals.reject },
        { name: 'Pending', value: totals.pending },
        { name: 'Cancelled', value: totals.cancelled }
    ];

    console.log("insights", getInsights?.result);

    if (insightsLoading) return <LoadingPage />;
    if (insightsError) return (
        <Box sx={{ width: "100%", minHeight: "35vh", padding: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography textAlign={"center"} variant="h2" color="black">
                We have encountered a glitch.
            </Typography>
            <Typography textAlign={"center"} variant="h6" color="black">
                No Insights found. We kindly request you to try again later.
            </Typography>
        </Box>
    );

    return (
        <Box sx={{ width: '100%', padding: 2, position: 'relative' }}>
            <Typography variant="h5" textAlign="center" gutterBottom>
                Meeting Insights
            </Typography>
            {insightsData.length === 0 && (
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1
                }}>
                    <Typography variant="h4" color="black">
                        Sorry, No Meeting request received
                    </Typography>
                </Box>
            )}
            <Grid container spacing={2}>
                <Grid item md={8}>
                    <Paper elevation={7} sx={{ padding: 2, borderRadius: 3 }}>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart data={insightsData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="accept" name="Accepted" fill="#4CAF50" />
                                <Bar dataKey="reject" name="Rejected" fill="#F44336" />
                                <Bar dataKey="pending" name="Pending" fill="#FFC107" />
                                <Bar dataKey="cancelled" name="Cancelled" fill="#9E9E9E" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item md={4}>
                    <Paper elevation={7} sx={{ padding: 2, borderRadius: 3 }}>
                        <Typography variant="h4" textAlign="center">
                            {totals.total}
                        </Typography>
                        <Typography textAlign="center">
                            Total Meeting Requests
                        </Typography>
                        <ResponsiveContainer width="100%" height={320} >
                            <PieChart>
                                <Pie
                                    data={pieData}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={60}
                                    fill="#8884d8"
                                    dataKey="value"
                                    label={({ name, value }) => `${name}: ${value}`}
                                >
                                    {pieData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                        </ResponsiveContainer>
                        {/* <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
                            <Typography>
                                Accepted Requests
                            </Typography>
                            <Typography>
                                {totals.accept}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
                            <Typography>
                                Rejected Requests
                            </Typography>
                            <Typography>
                                {totals.reject}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
                            <Typography>
                                Pending Requests
                            </Typography>
                            <Typography>
                                {totals.pending}
                            </Typography>
                        </Stack>
                        <Divider />
                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 2 }}>
                            <Typography>
                                Cancelled Requests
                            </Typography>
                            <Typography>
                                {totals.cancelled}
                            </Typography>
                        </Stack> */}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
