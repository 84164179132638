import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProfilePicWithUpdateButton from '../Components/DashboardComponent/ProfilePicWithUpdateButton'
import { gql, useQuery } from '@apollo/client';
import ProfileForm from '../Components/DashboardComponent/ProfileForm';
import BookMeeting from './BookMeeting';
import MeetingSetup, { IS_MEETING_SETUP_DONE } from './MeetingSetup';
import InsightsComponent from '../Components/DashboardComponent/InsightsComponent';

export default function Dashboard() {
  const [isAlreadySetup, setIsAlreadySetup] = useState({ isExist: false, detail: null });

  const { data: userData } = useQuery(
    gql`
          query {
          eventId,
            userId,
            role,
            name,
            company
          }
        `,
    { onError: (e) => console.log(JSON.stringify(e, null, 2)) }
  );
  const { data: isMeetingSetupDone, loading, error, refetch } = useQuery(IS_MEETING_SETUP_DONE, {
    onError: (e) => console.log(JSON.stringify(e, null, 2))
  });

  useEffect(() => {
    if (isMeetingSetupDone && isMeetingSetupDone.isMeetingSetupDone) {
      const parsedData = JSON.parse(isMeetingSetupDone.isMeetingSetupDone);
      setIsAlreadySetup(parsedData);
    }
  }, [isMeetingSetupDone]);
  const eventId = userData?.eventId
  // console.log(eventId, userData?.userId);
  return (
    <Box >
      {
        !userData && (
          <>
            <Typography variant='body1' color={"red"}>Sorry!, Something Went Wrong</Typography>
          </>
        )
      }
      {
        (userData?.role !== "visitor" && userData?.role !== "exhibitor") && (
          <>
            <Typography variant='body1' color={"red"}>Sorry!, you are not allowed</Typography>
          </>
        )
      }
      {
        userData?.role === "exhibitor" && (
          <>
            <Grid container spacing={2} >
              <Grid item md={12}>
                <Paper elevation={7} sx={{ padding: { xs: 2, md: 2 }, borderRadius: 3 }}>
                  <Typography variant="h4" align="center" sx={{ textTransform: "capitalize" }}>
                    {`Welcome, ${userData?.name}!`}
                  </Typography>
                  <Typography variant="h6" align="center" sx={{ textTransform: "capitalize", marginTop: 1 }}>
                    {`We are glad to have you from ${userData?.company}.`}
                  </Typography>
                  <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
                    {`Here are your latest updates and professional insights.`}
                  </Typography>
                </Paper>
              </Grid>
              {isAlreadySetup.isExist ? (<Grid item md={12}>
                <InsightsComponent exhibitorId={userData?.userId} eventId={eventId} />

              </Grid>) : (<Grid item md={12}>
                <MeetingSetup fromDashboard={true} reload={refetch} />
                <Stack direction="row" spacing={2}>
                <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  <ProfilePicWithUpdateButton userId={userData?.userId} role={userData?.role} eventId={eventId} />
                </Grid>
                <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                  <ProfileForm userId={userData?.userId} eventId={eventId} role={userData?.role} />
                </Grid>
                </Stack>

              </Grid>)}

            </Grid>
          </>
        )
      }
      {
        userData?.role === "visitor" && (
          <>
            <Grid container spacing={1} >
              <Grid item md={12}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ textTransform: "capitalize" }}
                >
                  {`${userData?.name}'s Profile`}
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ProfilePicWithUpdateButton userId={userData?.userId} role={userData?.role} eventId={eventId} />
              </Grid>
              <Grid item md={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <ProfileForm userId={userData?.userId} eventId={eventId} role={userData?.role} />
              </Grid>
            </Grid>
          </>
        )
      }
    </Box>
  )
}
