// import "./styles.css";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Box, Button, CircularProgress, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { ScheduleMeeting, timeSlotDifference } from "react-schedule-meeting";
import { toast, ToastContainer } from "react-toastify";
import { Icon } from "@iconify/react";
import { Controller, useForm } from "react-hook-form";

export default function BookMeeting({ exhibitorId, visitorId, handleCloseBookingUI }) {
    const [timeSlots, setTimeSlots] = useState([]);

    const { data, loading, error, refetch } = useQuery(GET_TIME_SLOTS, {
        variables: { exhibitorId },
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    const { data: bookedTimeSlotsData, loading: bookedTimeSlotsLoading, error: bookedTimeSlotsError, refetch: getBookedTime } = useQuery(GET_BOOKED_TIME_SLOTS, {
        variables: { exhibitorId },
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    const bookedTimeSlots = useMemo(() => typeof(bookedTimeSlotsData?.bookedTimeSlots) === "string" ? JSON.parse(bookedTimeSlotsData.bookedTimeSlots) : bookedTimeSlotsData?.bookedTimeSlots, [bookedTimeSlotsData]);
    const availableTimeSlots = useMemo(() => typeof(data?.getTimeSlots) === "string" ? JSON.parse(data?.getTimeSlots) : data?.getTimeSlots, [data]);
console.log(">><<<<<<<",typeof(data?.getTimeSlots));
    const generateTimeSlots = (start, end, duration, lunchStart, lunchEnd) => {
        let slots = [];
        let current = new Date(start);

        while (current < end) {
            const slotEnd = new Date(current.getTime() + duration * 60000);
            if (slotEnd > end) break;
            if (!(current >= lunchStart && current < lunchEnd)) {
                slots.push({ startTime: new Date(current), endTime: slotEnd });
            }
            current = slotEnd;
        }
        return slots;
    };

    useEffect(() => {
        if ( availableTimeSlots) {
            getBookedTime();
            // console.log(">>>>>>>>>>>>",availableTimeSlots);
            const [startHour, startMinute] = availableTimeSlots?.meetingStartTime?.split(':')?.map(Number);
            const [endHour, endMinute] = availableTimeSlots?.meetingEndTime?.split(':')?.map(Number);
            const [lunchStartHour, lunchStartMinute] = availableTimeSlots?.lunchStartTime ? availableTimeSlots?.lunchStartTime?.split(':')?.map(Number) : [null, null];
            const [lunchEndHour, lunchEndMinute] = availableTimeSlots?.lunchEndTime ? availableTimeSlots?.lunchEndTime?.split(':')?.map(Number) : [null, null];
            const includeLunch = availableTimeSlots?.includeLunch === "1";

            let currentDate = new Date(availableTimeSlots?.startDate);
            let endDate = new Date(availableTimeSlots?.endDate);

            const allSlots = [];

            while (currentDate <= endDate) {
                const startDateTime = new Date(currentDate);
                startDateTime.setHours(startHour, startMinute);

                const endDateTime = new Date(currentDate);
                endDateTime.setHours(endHour, endMinute);

                const lunchStartDateTime = lunchStartHour !== null ? new Date(currentDate).setHours(lunchStartHour, lunchStartMinute) : null;
                const lunchEndDateTime = lunchEndHour !== null ? new Date(currentDate).setHours(lunchEndHour, lunchEndMinute) : null;

                let slots = generateTimeSlots(startDateTime, endDateTime, parseInt(availableTimeSlots.meetingDuration), lunchStartDateTime, lunchEndDateTime);

                if (includeLunch && lunchStartDateTime && lunchEndDateTime) {
                    const lunchSlot = { startTime: lunchStartDateTime, endTime: lunchEndDateTime };
                    slots = timeSlotDifference(slots, [lunchSlot]);
                }

                allSlots.push(...slots);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            const bookedSlots = bookedTimeSlots?.map(slot => ({
                startTime: new Date(JSON.parse(slot.requested_slot).startTime),
                endTime: new Date(JSON.parse(slot.requested_slot).endTime)
            })) || [];

            const availableSlots = timeSlotDifference(allSlots, bookedSlots);

            setTimeSlots(availableSlots);
        }
    }, [availableTimeSlots, bookedTimeSlots]);

    if (loading || bookedTimeSlotsLoading) return <p>Loading...</p>;
    if (error || bookedTimeSlotsError) return <p>Error: {error?.message || bookedTimeSlotsError?.message}</p>;

    return (
        <Box>
            {/* <Button onClick={()=> onClose()} variant="contained" color="error">Cancel</Button> */}
            <BookingUI exhibitorId={exhibitorId} meetingDuration={availableTimeSlots.meetingDuration} onClose={handleCloseBookingUI} timeSlots={timeSlots} refetch={refetch} />
        </Box>
    );
}

function BookingUI({ exhibitorId, meetingDuration, timeSlots,onClose,refetch }) {
    const [timeSlotSelected, setTimeSlotSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [appointmentDate, setAppointmentDate] = useState(null);
    const [appointmentTime, setAppointmentTime] = useState(null);
    const [title, setTitle] = useState(null);
    const { control, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();

    const [bookAppointment, { loading }] = useMutation(BOOK_APPOINTMENT, {
        onCompleted: (data) => {
            toast.success(data?.bookAppointment);
            handleClose();
            setTimeout(() => {
                refetch();
                onClose();
            }, 3000);
        },
        onError: (error) => {
            toast.error(error.message);
            console.error('Error booking appointment: ', error);
        }
    });

    const handleTimeSlotSelect = (selectedTimeSlot) => {
        if (!selectedTimeSlot.startTime || !(selectedTimeSlot.startTime instanceof Date)) {
            console.error('Invalid time slot data:', selectedTimeSlot);
            return;
        }

        const startTime = selectedTimeSlot.startTime.toTimeString().slice(0, 5);
        const endTime = new Date(selectedTimeSlot.startTime.getTime() + meetingDuration * 60000).toTimeString().slice(0, 5);
        const bookingDate = selectedTimeSlot.startTime.toISOString().slice(0, 10);
        const appointmentTime = `${startTime} - ${endTime}`;

        setAppointmentDate(bookingDate);
        setAppointmentTime(appointmentTime);
        setTimeSlotSelected(selectedTimeSlot);
        setOpen(true);
    };

    const handleClose = () => {
        setAppointmentDate(null);
        setAppointmentTime(null);
        setTimeSlotSelected(null);
        setTitle(null);
        setOpen(false);
    };

    const bookSelectedTimeSlot = async () => {
        if (!title) {
            setError('title', {
                type: 'manual',
                message: 'Please Enter Meeting Title'
            });
            return;
        } else {
            clearErrors('title');
        }

        try {
            const data = JSON.stringify(timeSlotSelected);
            const submission = await bookAppointment({ variables: { exhibitorId, data, title } });
            console.log('Booking confirmed:', submission);
            handleClose()
            
        } catch (error) {
            console.error('Failed to book appointment:', error);
        }
    };

    return (
        <>
            <ScheduleMeeting
                borderRadius={10}
                primaryColor="#3f5b85"
                eventDurationInMinutes={parseInt(meetingDuration)}
                availableTimeslots={timeSlots}
                onStartTimeSelect={handleTimeSlotSelect}
            />

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(7px)',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px'
                    }}
                >
                    <IconButton style={{ position: 'absolute', right: 0, top: 0 }} onClick={handleClose}>
                        <Icon icon="zondicons:close" />
                    </IconButton>
                    <Typography
                        id="modal-title"
                        variant="h5"
                        component="div"
                        sx={{ color: 'black', textAlign: 'center', marginBottom: 3 }}
                    >
                        Are you sure you want to book this appointment?
                    </Typography>
                    <Typography id="modal-description" variant="body1" component="div" sx={{ color: 'black', textAlign: 'center', marginBottom: 3 }}>
                        {`Appointment Date: ${appointmentDate}`}
                    </Typography>
                    <Typography id="modal-description" variant="body2" component="div" sx={{ color: 'black', textAlign: 'center', marginBottom: 3 }}>
                        {`Appointment Time: ${appointmentTime}`}
                    </Typography>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                type="text"
                                label="Meeting Agenda"
                                value={title}
                                color="secondary"
                                onChange={(e) => setTitle(e.target.value)}
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                fullWidth
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    borderRadius: '8px',
                                    '& .MuiInputLabel-root': { color: 'black' },
                                    '& .MuiInputBase-input': { color: 'black' }
                                }}
                            />
                        )}
                    />
                    <Button
                        type="button"
                        onClick={bookSelectedTimeSlot}
                        variant="contained"
                        disabled={loading}
                        sx={{
                            marginTop: '16px',
                            width: '100%',
                            backgroundColor: 'rgba(204, 119, 34, 0.5)',
                            color: 'white',
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: 'rgba(24, 119, 242, 0.5)',
                                boxShadow: 'none'
                            }
                        }}
                    >
                        Book Now {loading && <CircularProgress size={20} color="secondary" sx={{ marginLeft: 2 }} />}
                    </Button>
                </Box>
            </Modal>

            <ToastContainer />
        </>
    );
}

const GET_TIME_SLOTS = gql`
  query($exhibitorId: String!) {
    getTimeSlots(exhibitorId: $exhibitorId)
  }
`;

const GET_BOOKED_TIME_SLOTS = gql`
  query($exhibitorId: String!) {
    bookedTimeSlots(exhibitorId: $exhibitorId)
  }
`;
const BOOK_APPOINTMENT = gql`
  mutation($exhibitorId: String! $data:String!,$title:String!) {
    bookAppointment(exhibitorId: $exhibitorId data:$data,title:$title)
  }
`;

