import { Backdrop, Box, CircularProgress, styled } from '@mui/material'
import React from 'react'

const DisabledBackground = styled(Box)({
  width: "100%",
  height: "100%",
  position: "fixed",
  background: "#ccc",
  opacity: 0.5,
  zIndex: 1
});
const CircularLoading = () => (
  <>
    <CircularProgress
      size={70}
      sx={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2
      }}
      color="inherit"
    />
    <DisabledBackground />
  </>
);

export default function LoadingPage() {
  return (
//     <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", minHeight:"100vh", width:"100%"}}>
//         <Backdrop
//   sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//   open={true}
  
// >
//   <CircularProgress color="inherit" />
// </Backdrop>
//         </Box>
<CircularLoading /> 
  )
}
