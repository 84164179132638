import React, { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation, gql, useQuery } from '@apollo/client';
import {
    TextField, Button, Grid, Typography, Box, Checkbox, FormControlLabel,
    Stack,
    Paper,
    Card,
    CardMedia,
    CardContent,
    Divider
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScheduleMeeting, timeSlotDifference } from 'react-schedule-meeting';
import LoadingPage from '../Components/LoadingPage';
import day from "../Assets/days.jpeg"
import date from "../Assets/dates.jpeg"
import duration from "../Assets/duration.jpeg"
import lunch from "../Assets/lunch.jpeg"

const SET_AVAILABILITY = gql`
  mutation SetAvailability(
    $exhibitor_id: String!,
    $startDate: String!,
    $endDate: String!,
    $meetingStartTime: String!,
    $meetingEndTime: String!,
    $lunchStartTime: String,
    $lunchEndTime: String,
    $meetingDuration: String!,
    $includeLunch: Boolean!
  ) {
    setAvailability(
      exhibitor_id: $exhibitor_id,
      startDate: $startDate,
      endDate: $endDate,
      meetingStartTime: $meetingStartTime,
      meetingEndTime: $meetingEndTime,
      lunchStartTime: $lunchStartTime,
      lunchEndTime: $lunchEndTime,
      meetingDuration: $meetingDuration,
      includeLunch: $includeLunch
    )
  }
`;
const GET_USER = gql`
  query {
    userId
    role
  }
`;
const GETEVENT_DATETIME = gql`
  query {
    startDate
    endDate
    startTime
    endTime
  }
`;

export const IS_MEETING_SETUP_DONE = gql`
  query {
    isMeetingSetupDone
  }
`;

const schema = yup.object().shape({
    // availabilityDateFrom: yup.date().required("Availability start date is required").min(new Date(), "Date cannot be in the past"),
    // availabilityDateTo: yup.date().required("Availability end date is required").min(yup.ref('availabilityDateFrom'), "End date must be after start date"),
    // startTime: yup.string().required("Start time is required"),
    // endTime: yup.string().required("End time is required").test('is-greater', 'End time must be later than start time', function (value) {
    //     const { startTime } = this.parent;
    //     return startTime < value;
    // }),
    meetingDuration: yup.number().required("Meeting duration is required").positive("Meeting duration must be positive").integer("Meeting duration must be an integer")
});

const CardItem = ({ title, value, image }) => (<>
    {/*// <Card sx={{   borderRadius: 2 }}>
    //      <CardMedia
    //         component="img"
    //         height="140"
    //         image={image} // Replace with your image path
    //         alt={title}
    //     /> 
    //     <CardContent>*/}
    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
        <Typography variant="h6" minWidth={"40%"} component={"span"} color="text.secondary" textAlign="left">
            {title}
        </Typography>
        <Typography variant="h6" component="span" color="text.secondary" textAlign="right">
            {value}
        </Typography>
    </Stack>
    <Divider />
    {/* //     </CardContent>
    // </Card> */}
</>
);

export default function MeetingSetup({fromDashboard, reload}) {
    const [isAlreadySetup, setIsAlreadySetup] = useState({ isExist: false, detail: null });
    const [isModifying, setIsModifying] = useState(false);

    const { data: userData } = useQuery(GET_USER, {
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });
    const { data: dateTime } = useQuery(GETEVENT_DATETIME, {
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });
    console.log("dateTime", dateTime);
    const exhibitorId = userData?.userId;

    const { data: isMeetingSetupDone, loading, error } = useQuery(IS_MEETING_SETUP_DONE, {
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });

    useEffect(() => {
        if (isMeetingSetupDone && isMeetingSetupDone.isMeetingSetupDone) {
            const parsedData = JSON.parse(isMeetingSetupDone.isMeetingSetupDone);
            setIsAlreadySetup(parsedData);
        }
    }, [isMeetingSetupDone]);

    const [timeDuration, setTimeDuration] = useState("");
    const { control, handleSubmit, watch, setValue, setError, clearErrors } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            availabilityDateFrom: dateTime?.startDate,
            availabilityDateTo: dateTime?.endDate,
            startTime: dateTime?.startTime,
            endTime: dateTime?.endTime,
            lunchStart: "13:00",
            lunchEnd: "14:00",
            meetingDuration: timeDuration ,
            includeLunch: false
        }
    });

    const [setAvailability] = useMutation(SET_AVAILABILITY);

    const includeLunch = watch('includeLunch');

    const onSubmit = async (data) => {
        const newData = {
            exhibitor_id: exhibitorId,
            startDate: dateTime?.startDate,
            endDate: dateTime?.endDate,
            meetingStartTime: dateTime?.startTime,
            meetingEndTime: dateTime?.endTime,
            lunchStartTime: data.lunchStart,
            lunchEndTime: data.lunchEnd,
            meetingDuration: data.meetingDuration.toString(),
            includeLunch: data.includeLunch,
        };

        try {
            await setAvailability({ variables: newData });
            toast.success('Availability set successfully!');
            setIsAlreadySetup({ isExist: true, detail: newData });
            setIsModifying(false);
            if(fromDashboard){reload()}
        
        } catch (error) {
            toast.error('Failed to set availability');
        }
    };

    if (loading) return <LoadingPage />;
    if (error) return <p>Something went wrong...</p>;

    return (
        <>
            {/* <Typography variant="h3" textAlign={"center"} gutterBottom>Meeting Setup Page</Typography> */}
            {!isModifying && isAlreadySetup.isExist ? (
                <Paper elevation={7} sx={{ padding: { xs: 2, md: 4 }, borderRadius: 3 }}>
                    <Stack direction="column" spacing={2} my={4}>
                        <Typography variant="h4" textAlign="center">Meeting Setup</Typography>
                    </Stack>
                    <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid item md={12} >
                            <CardItem
                                title="Meeting Days"
                                value={`${new Date(isAlreadySetup.detail.startDate).toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })} - ${new Date(isAlreadySetup.detail.endDate).toLocaleDateString('en-GB', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' })}`}
                                image={date}
                            />


                        </Grid>
                        <Grid item md={12}>
                            <CardItem
                                title="Meeting Time"
                                value={`${new Date('1970-01-01T' + isAlreadySetup.detail.meetingStartTime + ':00').toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - ${new Date('1970-01-01T' + isAlreadySetup.detail.meetingEndTime + ':00').toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                                image={day}
                            />
                        </Grid>
                        {isAlreadySetup.detail.includeLunch && (
                            <Grid item md={12}>
                                <CardItem
                                    title="Meeting Break Time"
                                    value={`${new Date('1970-01-01T' + isAlreadySetup.detail.lunchStartTime + ':00').toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })} - ${new Date('1970-01-01T' + isAlreadySetup.detail.lunchEndTime + ':00').toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                                    image={lunch}
                                />
                            </Grid>
                        )}
                        <Grid item md={12}>
                            <CardItem title="Meeting Duration" value={`${isAlreadySetup.detail.meetingDuration} Minute`} image={duration} />
                        </Grid>
                    </Grid>
                    <Stack direction="row" spacing={2} justifyContent="center" my={5}>
                        <Button variant="contained" onClick={() => setIsModifying(true)}>Modify Setup</Button>
                    </Stack>
                </Paper>
            ) : (
                <Paper elevation={7} sx={{ padding: { xs: 2, md: 2 }, borderRadius: 3 }}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={6} md={3}>
                                <Controller
                                    name="availabilityDateFrom"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={field.onChange}
                                            customInput={<TextField label="Availability Start Date" fullWidth error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Controller
                                    name="availabilityDateTo"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DatePicker
                                            selected={field.value}
                                            onChange={field.onChange}
                                            customInput={<TextField label="Availability End Date" fullWidth error={!!fieldState.error} helperText={fieldState.error?.message} />}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Controller
                                    name="startTime"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            type="time"
                                            label="Start Time"
                                            fullWidth
                                            {...field}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Controller
                                    name="endTime"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            type="time"
                                            label="End Time"
                                            fullWidth
                                            {...field}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Controller name="includeLunch" control={control} render={({ field }) => <Checkbox {...field} />} />}
                                    label="Include Break"
                                />
                            </Grid>
                            {includeLunch && (
                                <>
                                    <Grid item xs={6} md={6}>
                                        <Controller
                                            name="lunchStart"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    type="time"
                                                    label="Break Start"
                                                    fullWidth
                                                    {...field}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Controller
                                            name="lunchEnd"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    type="time"
                                                    label="Break End"
                                                    fullWidth
                                                    {...field}
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6} md={12}>
                                <Controller
                                    name="meetingDuration"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField
                                            type="number"
                                            label="Meeting Duration (minutes)"
                                            placeholder='Choose your meeting time duration.'
                                            fullWidth
                                            {...field}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent={"center"} py={2} spacing={2}>
                                    <Button type="submit" variant="contained" color="primary">Set Availability</Button>
                                    {isAlreadySetup.isExist && isModifying && (
                                        <Button variant="contained" color='error' onClick={() => setIsModifying(false)}>Close</Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>
                    {!isAlreadySetup.isExist && (
                        <Typography variant="body1" component={"span"} color="Highlight" textAlign="center">
                            Your company details will be visible to visitors and you will be eligible for booking meetings only after setting up your availability and meeting duration.
                        </Typography>
                    )}
                </Paper>
            )}
            <ToastContainer />
        </>
    );
}


